<template>
  <div class="breadcrumbs">
    <div
      v-for="(navItem, index) in navigation"
      :key="navItem.href"
      :class="[
        'breadcrumbs__item',
        {
          'text-manatee': index < navigation.length - 1,
          'text-black':
            navigation.length === 1 || navigation.length - 1 === index,
        },
      ]"
    >
      <a
        :href="navItem.href"
        class="breadcrumbs__item-link"
        >{{ navItem.title }}</a
      >
      <CaretRight v-if="index !== navigation.length - 1" class="breadcrumbs__item-icon" />
    </div>
  </div>
</template>

<script>
const CaretRight = () => import('~/components/icons/IconCaretRight.vue');

export default {
  components: {
    CaretRight,
  },
  props: {
    navigation: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.breadcrumbs {
  @apply py-10px sm:py-5 font-sans inline-flex gap-3;

  &__item {
    @apply flex items-center gap-2;

    &:hover {
      @apply text-blue-600;
    }

    &-link {
      @apply text-sm sm:text-md text-current;

      &:hover {
        @apply no-underline;
      }
    }

    &-icon {
      @apply text-manatee;
    }
  }
}
</style>
