var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"breadcrumbs"},_vm._l((_vm.navigation),function(navItem,index){return _c('div',{key:navItem.href,class:[
      'breadcrumbs__item',
      {
        'text-manatee': index < _vm.navigation.length - 1,
        'text-black':
          _vm.navigation.length === 1 || _vm.navigation.length - 1 === index,
      },
    ]},[_c('a',{staticClass:"breadcrumbs__item-link",attrs:{"href":navItem.href}},[_vm._v(_vm._s(navItem.title))]),_vm._v(" "),(index !== _vm.navigation.length - 1)?_c('CaretRight',{staticClass:"breadcrumbs__item-icon"}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }